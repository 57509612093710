import React, { useEffect, useState } from 'react'
import styles from './styles/type1.module.scss'
import classNames from 'classnames'

interface NavType1Props {
  list: Array<{
    path: string
    name: string
    sub?: Array<{ name: string; path: string }>
  }>
}

export default function Type1({ list }: NavType1Props) {
  const [isShow, setIsShow] = useState<{
    show: boolean
    id: number
    children: Array<{ name: string; path: string }>
  }>({
    show: false,
    id: 0,
    children: [],
  })

  useEffect(() => {
    if (list && list.length > 0) {
      if (list[0].sub && list[0].sub.length > 0) {
        setIsShow({
          show: true,
          id: 0,
          children: list[0].sub,
        })
      }
    }
  }, [list])

  return (
    <div className={styles.type1}>
      <ul className={styles.left_wrapper}>
        {list.map((item, index) => (
          <li
            key={index}
            className={styles.item}
            onMouseEnter={() =>
              setIsShow({
                show: true,
                id: index,
                children: item?.sub || [],
              })
            }
          >
            <a
              href={item.path}
              className={classNames(['text-elli-1', styles.link])}
            >
              {item.name}
            </a>
          </li>
        ))}
      </ul>
      {isShow.children && isShow.children.length > 0 ? (
        <>
          <hr style={{ marginLeft: '10px' }} />
          <ul className={styles.right_wrapper}>
            {isShow.children.map((item, index) => (
              <li key={index} className={styles.item}>
                <a
                  href={item.path}
                  className={classNames(['text-elli-1', styles.link])}
                >
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </>
      ) : null}
    </div>
  )
}
