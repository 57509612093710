import React, { useState } from 'react'
import NavType1 from '../type1'
import PullDownWrite from '@/assets/svg/pull_down_write.svg'
import styles from './index.module.scss'
import classNames from 'classnames'

interface Nav {
  menu: Array<{
    name: string
    path: string
    sub?: Array<{ name: string; path: string }>
  }>
}

export default function HeaderNav({ menu }: Nav) {
  const [isShow, setIsShow] = useState({
    show: false,
    id: 0,
  })

  return (
    <div className={styles.headerNav}>
      <ul className={styles.navList}>
        {(menu || []).map((item, index) => {
          if (item.sub && item.sub.length > 0) {
            return (
              <li
                key={index}
                onMouseEnter={() =>
                  setIsShow({
                    show: true,
                    id: index,
                  })
                }
                onMouseLeave={() =>
                  setIsShow({
                    show: false,
                    id: index,
                  })
                }
              >
                {item.path !== '' ? (
                  <a
                    href={item.path}
                    className={classNames([
                      'text-elli-1',
                      styles.link_children,
                    ])}
                  >
                    {item.name}
                    <div
                      className={classNames([
                        styles.icon,
                        isShow.id === index && isShow.show && styles.icon_tran,
                      ])}
                    >
                      <PullDownWrite></PullDownWrite>
                    </div>
                  </a>
                ) : (
                  <span
                    className={classNames([
                      'text-elli-1',
                      styles.link_children,
                    ])}
                  >
                    {item.name}

                    <div
                      className={classNames([
                        styles.icon,
                        isShow.id === index && isShow.show && styles.icon_tran,
                      ])}
                    >
                      <PullDownWrite></PullDownWrite>
                    </div>
                  </span>
                )}
                {isShow.id === index && isShow.show && (
                  <NavType1 list={item?.sub || []}></NavType1>
                )}
              </li>
            )
          }
          return (
            <li key={index}>
              <a
                href={item.path}
                className={classNames(['text-elli-1', styles.link])}
              >
                {item.name}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
